/*
*===============DdmgCloudPlatform3 V2.0===============
* DdmgCloudPlatform3 V2.0
* Copyright@大大买钢2018,All Rights Reserved
* Author: XieZhuoxun(59296309@qq.com)
* Date: 2018/9/7  09:18
* TODO: 将图片上传到阿里云OSS服务器公用组件
*===============DdmgCloudPlatform3 V2.0===============
*/
<template>
  <div class="show-big-image">
    <div
         style="font-size: 14px"
         :class="requiredIcon ? 'star' : ''"
    >
      {{ title }}
    </div>
    <div
         class="file-box flex flex-v"
         style="height: 80px;border-radius: 20px"
         :style="`width: ${width}`"
         v-for="(item, index) in imageUrl"
         :key="item"
    >
      <!-- <img
        :src="item"
        :style="`width: ${width};height: ${height};`"
      /> -->
      <el-image
           :src="item"
           :preview-src-list="previewImgs"
           :style="`width: ${width};height: ${height};`"
      ></el-image>
      <!-- <div class="flex layer">
        <a
          class="bt-icon el-icon-delete"
          @click="onDeleteEvent(item, index)"
          v-if="!showDetail && showDelete && !onlyShowImg"
        />
        <div class="flex-1" />
        <a
          class="bt-icon el-icon-zoom-in"
          @click="(enlargeImage = item), (isEnlargeImage = true)"
        />
      </div> -->
      <div
           class="flex layer">
        <img src="./img/5.svg" alt="删除" @click="onDeleteEvent(item, index)"
             v-if="!showDetail && showDelete && !onlyShowImg">
      </div>
    </div>
    <div

         class="file-box"
         v-if="
        !showDetail &&
        (imageUrl.length < number || !imageUrl.length) &&
        !onlyShowImg
      "
    >
      <!-- <el-image
        :src="tipSrc"
        :preview-src-list="[tipSrc]"
        :style="`width: ${width};height: ${height};`"
      ></el-image>
      <input
        class="file-btn"
        @change="chooseImage"
        :multiple="number > 1"
        type="file"
        :style="`width: ${width}`"
      /> -->
      <el-upload
           :file-list="imageUrl"
           :show-file-list="false"
           class="upload-demo"
           drag
           action=""
           :http-request="httpRequest"
           multiple
           ref="elUpload">
        <el-image
             :src="tipSrc"
             :style="`width: ${width};height: ${height};`"
        ></el-image>
        <!-- <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> -->
      </el-upload>
    </div>

    <!-- <el-dialog
      :visible.sync="isEnlargeImage"
      :modal="false"
      :modal-append-to-body="appendToBody"
      top="3%"
      width="60%"
      title="图片预览"
    >
      <img
        style="width: 100%"
        :src="enlargeImage"
      />
    </el-dialog> -->
  </div>
</template>

<script>
    import request from "@/plugin/axios";
    import dayjs from "dayjs";
    import defaultTipSrc from "./img/upload.svg";
    import * as HttpServices from "@/utils/http";
    import {
        getLoginPayload,
        getPayload
    } from "@/utils/server";

    export default {
        name: "ddmg-upload-img",
        props: {
            onlyShowImg: { // 只显示图片
                type: Boolean,
                default: false,
            },
            uploadType: { //允许上传的类型
                type: Array,
                default: ['png','gif','jpg','jpeg','bmp','webp','psd','svg','tiff']
            },
            number: { //  允许上传的图片数量
                type: Number,
                default: 1,
            },
            requiredIcon: { // 是否需要*  必填标志
                type: Boolean,
                default: false,
            },
            size: {
                //  图片上传最大    单位：M
                type: Number,
                default: 6,
            },
            tipSrc: { // 上传图标地址
                type: String,
                default () {
                    return defaultTipSrc;
                },
            },
            appendToBody: { //
                type: Boolean,
                default () {
                    return false;
                },
            },
            value: {
                type: [String, Boolean],
                required: true,
                default () {
                    return false;
                },
            },
            showDelete: { // 显示删除图标
                type: Boolean,
                default: true,
            },
            title: {
                type: String,
                default () {
                    return "";
                },
            },
            prop: {
                type: String,
            },
            width: {
                type: String,
                default () {
                    return "80px";
                },
            },
            height: {
                type: String,
                default () {
                    return "80px";
                },
            },
            dir: {
                //上传图片的根目录
                type: String,
                // required: true,
                default () {
                    return "";
                },
            },
            subDir: {
                //上传图片的二级目录
                type: String,
                // required: true,
                default () {
                    return "";
                },
            },
        },
        data () {
            return {
                isEnlargeImage: false, //放大图片
                enlargeImage: "",
                imgTypes: ['.png','.gif','.jpg','.jpeg','.bmp','.webp','.psd','.svg','.tiff'],
            };
        },

        computed: {
            imageUrl: {
                get: function () {
                    return Array.isArray(this.value) ? this.value : [];
                },
                set: function (val) {
                    this.$emit("input", val);
                },
            },
            showDetail () {
                return this.$parent.showDetail;
            },
            previewImgs(){
                //需要被预览的文件
                let arr = []
                this.imageUrl.reduce(function(p,item){
                    arr.push(item)
                }.bind(this),0)
                return arr
            },
        },
        methods: {
            // onExceed(file, fileList){
            //     this.$message.error("超出最大上传数量：" + this.number);
            // },
            httpRequest(e){
                console.log("上传的文件2",this.imageUrl,this.imageUrl.length)
                const file = e.file
                // 有文件类型限制，做校验
                if (this.uploadType.length > 0) {
                    const names = file.name.split('.');
                    let fileNameType = "";
                    if (names && names.length > 0) {
                        fileNameType = names[names.length - 1];
                    }
                    if (fileNameType) {
                        // 统一转小写比对
                        fileNameType = fileNameType.toLowerCase();
                        const fileTypes = this.uploadType.map(fileType => {
                            return fileType.toLowerCase();
                        })
                        if (fileTypes.indexOf(fileNameType) == -1) {
                            this.$message.warning("请上传指定类型附件,当前类型：" + fileNameType);
                            return;
                        }
                    } else {
                        this.$message.warning("上传的文件格式有问题，请重新选择上传文件！");
                        return
                    }
                }
                if (this.imageUrl.length > this.number) {
                    this.$message.error("超出最大上传数量：" + this.number);
                    return;
                }
                this.uploadImage(file);
            },
            // async chooseImage (e) {
            //   const files = Array.from(e.target.files);
            //   for (let i = 0; i < files.length; i++) {
            //     console.log('333333', files[i].type)
            //     if (this.uploadType.length > 0 && (this.uploadType.indexOf(files[i].type.split('/')[0]) === -1 && this.uploadType.indexOf(files[i].type.split('/')[1]) === -1)) {

            //       this.$message.error("请上传指定类型数据,当前类型：" + files[i].type);

            //       return
            //     }
            //   }
            //   if (files.length + this.imageUrl.length > this.number) {
            //     this.$message.error("超出最大图片数量：" + this.number);
            //     return;
            //   }
            //   files.forEach((file) => {
            //     this.uploadImage(file);
            //   });
            // },
            async uploadImage (file) {
                // 上传
                const isLt6M = file.size / 1024 / 1024 > this.size;
                if (isLt6M) {
                    this.$message({
                        type: "error",
                        message: "选择的图片不能大于" + this.size + "M!",
                    });
                    return false;
                }
                console.log("file chooseImage=", JSON.stringify(file), file);
                const payload = getLoginPayload("tmw001140", {dir:'ddmg'});
                const res = await HttpServices.startApiReq(payload);
                console.log("chooseImage res=====", res);
                if (res.msgcode === 1) {
                    const data = res.resultdata;
                    const filename = new Date().getTime() + this.getSuffix(file.name);
                    const formData = new FormData();
                    formData.append(
                        "key",
                        `${data.dir}/${dayjs().format("YYYYMMDD")}/${filename}`
                    ); //存储在oss的文件路径
                    formData.append("policy", data.policy); //policy
                    formData.append("OSSAccessKeyId", data.accessid); //accessKeyId
                    formData.append("success_action_status", "200"); //成功后返回的操作码
                    formData.append("Signature", data.signature); //签名
                    formData.append("callback", data.callback);
                    formData.append("file", file);
                    const url = data.host;
                    request({
                        url,
                        method: "post",
                        data: formData,
                    })
                        .then((result) => {
                            console.log("upload then===", result);
                            if (result.msgcode === 1) {
                                const url = result.fileurl;
                                console.log("filePath ======", url);
                                console.log("filePath this.imageUrl this ======", this);
                                console.log("filePath this.imageUrl ======", this.imageUrl);
                                this.$emit("input", [...this.imageUrl, url]);
                                this.$emit("add", url);
                                console.log("this.imageUrl ==", this.imageUrl);
                                this.$message.success("图片上传成功!");
                            } else {
                                this.$message.error("图片上传失败!");
                            }
                        })
                        .catch((e) => {
                            console.log("upload catch===", e);
                            console.log(e);
                        });
                }
            },
            getSuffix (fileName) {
                var pos = fileName.lastIndexOf(".");
                var suffix = "";
                if (pos != -1) {
                    suffix = fileName.substring(pos);
                }
                return suffix;
            },
            onDeleteEvent (item, idx) {
                this.$confirm("是否删除此图片吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.imageUrl = this.imageUrl.filter((it) => it != item);

                        this.$emit(
                            "input",
                            this.imageUrl.filter((it) => it != item)
                        );
                        this.$emit("delete", item, idx);

                    })
                    .catch((meg) => console.log(meg));
            },
        },
    };
</script>

<style lang="scss" scoped>
  @import "./sass/uploadImage";

  .star::before {
    content: "* ";
    color: #f56c6c;
  }
  .upload-demo{
    ::v-deep.el-upload{
      .el-upload-dragger{
        width:80px;
        height:80px;
        border-radius:6px;
        border:none;
        display:flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
      }
    }
    .el-icon-upload{
      margin: unset;
      font-size: 45px;
    }
  }
</style>
